import cn from 'classnames';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { productPlaceholder } from '#assets/svg/product-placeholder';
import { ArrowNextIcon } from '#components/icons/arrow-next-icon';
import { ArrowPrevIcon } from '#components/icons/arrow-prev-icon';
import type { Seller } from '#framework/__generated__';
import { useUnescaped } from '#lib/use-unescaped';

import { Image } from './image';
import { StructuredData } from './structured-data';
import { Navigation, Swiper, SwiperSlide } from './swiper';

const VendorItem: FC<{ item: Seller }> = ({ item }) => {
  const vendorName = useUnescaped(item.name);

  const onVendorClick = (slug: string) => {
    gtag('event', 'select_content', {
      content_type: 'vendor',
      content_id: slug,
    });
  };

  return (
    <>
      <StructuredData
        data={{
          '@type': 'Organization',
          name: vendorName ?? item.name,
          image: item.logo?.source,
          url: `/leverandorer/${item.token}`,
        }}
      />
      <a
        href={`/leverandorer/${item?.token}`}
        className={cn(
          'block p-1 text-center rounded bg-light relative overflow-hidden cursor-pointer border-2',
          'border-light'
        )}
        role='button'
        onClick={() => onVendorClick(item?.token)}
      >
        <div className='flex items-center justify-center h-20 w-auto relative overflow-hidden my-0'>
          <Image
            asset={item.logo}
            src={productPlaceholder}
            alt={vendorName!}
            layout='fill'
            objectFit='contain'
          />
        </div>
      </a>
    </>
  );
};

export const SolidBoxedVendorMenu: FC<{
  items: Seller[];
  className: string;
}> = ({ items }) => {
  const { t } = useTranslation('common');

  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const breakpoints = useMemo(
    () => ({
      0: {
        slidesPerView: Math.min(1, items?.length ?? 1),
      },

      320: {
        slidesPerView: Math.min(2, items?.length ?? 2),
      },

      620: {
        slidesPerView: Math.min(3, items?.length ?? 3),
      },

      820: {
        slidesPerView: Math.min(4, items?.length ?? 4),
      },

      1100: {
        slidesPerView: Math.min(5, items?.length ?? 5),
      },

      1280: {
        slidesPerView: Math.min(6, items?.length ?? 6),
      },
    }),
    [items?.length ?? 0]
  );

  return (
    <div className='relative'>
      <Swiper
        id='vendor-card-menu'
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current!, // Assert non-null
          nextEl: nextRef.current!, // Assert non-null
        }}
        breakpoints={breakpoints}
        slidesPerView={7}
        spaceBetween={10}
      >
        {items?.map((vendor: Seller, idx: number) => (
          <SwiperSlide key={idx}>
            <VendorItem item={vendor} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        ref={prevRef}
        className='banner-slider-prev w-8 h-8 flex items-center justify-center text-heading bg-light shadow-300 outline-none rounded-full absolute top-1/2 -mt-4 z-10 cursor-pointer -left-3 lg:-left-4 focus:outline-none'
      >
        <span className='sr-only'>{t('text.previous')}</span>
        <ArrowPrevIcon />
      </div>

      <div
        ref={nextRef}
        className='banner-slider-next w-8 h-8 flex items-center justify-center text-heading bg-light shadow-300 outline-none rounded-full absolute top-1/2 -mt-4 z-10 cursor-pointer -right-3 lg:-right-4 focus:outline-none'
      >
        <span className='sr-only'>{t('text.next')}</span>
        <ArrowNextIcon />
      </div>
    </div>
  );
};
