import { gql } from '@apollo/client';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

import { useBannersQuery } from '#framework/__generated__';

const ErrorMessage = loadable(() => import('#components/ui/error-message'), {
  resolveComponent: (components) => components.ErrorMessage,
});
const BannerWithSearch = loadable(
  () => import('#components/banners/banner-with-search'),
  {
    resolveComponent: (components) => components.BannerWithSearch,
  }
);
/*const BannerShort = loadable(() => import('#components/banners/banner-short'), {
  resolveComponent: (components) => components.BannerShort,
});*/

const MAP_BANNER_TO_GROUP: Record<string, any> = {
  classic: BannerWithSearch,
  //modern: BannerShort,
  standard: BannerWithSearch,
  default: BannerWithSearch,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BANNERS_QUERY = gql`
  query Banners {
    storefrontSlides(group: "site-banner") {
      id
      title
      description
      featuredAsset {
        id
        source
      }
    }
  }
`;

const Banner: React.FC<{ layout: string }> = ({ layout }) => {
  const { data, error } = useBannersQuery();
  if (error) return <ErrorMessage message={error.message} />;
  const Component = layout
    ? MAP_BANNER_TO_GROUP[layout]
    : MAP_BANNER_TO_GROUP.default;
  return <Component banners={data?.storefrontSlides} layout={layout} />;
};
Banner.propTypes = {
  layout: PropTypes.string.isRequired,
};

export default Banner;
