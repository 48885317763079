import { SolidBoxedVendorMenu } from '#components/ui/solid-boxed-vendor';
import type { Seller } from '#framework/__generated__';

interface StaticSidebarVerticalRectangleVendorsProps {
  notFound: boolean;
  loading: boolean;
  vendors: Seller[];
}

export const StaticSidebarVerticalRectangleVendors = ({
  notFound,
  vendors,
  loading,
}: StaticSidebarVerticalRectangleVendorsProps) => {
  /*if (loading) {
    return (
      <div className='hidden xl:block'>
        <div className='w-full h-52 flex justify-center mt-8 px-2'>
          <BakeryVendorLoader />
        </div>
      </div>
    );
  }*/

  return (
    <div className='w-full bg-gray-100'>
      {!notFound ? (
        <div className='pt-5 px-4 lg:px-8 lg:py-4 lg:pb-0'>
          <SolidBoxedVendorMenu items={vendors} className='py-4' />
        </div>
      ) : (
        <div className='min-h-full pt-6 pb-8 px-9 lg:p-8'>
          {/*<NotFound text='text-no-vendor' className='h-96' />*/}
        </div>
      )}
    </div>
  );
};

export default StaticSidebarVerticalRectangleVendors;
