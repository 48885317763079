import loadable from '@loadable/component';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import { SiteLayout } from '#components/layouts/site-layout';
import { Standard } from '#components/layouts/standard';
import { useSearchParam } from '#lib/use-search-param';
import type { RenderablePage } from '#types/page';
import { useCategoryQuery } from '#framework/__generated__';

const CartCounterButton = loadable(
  () => import('#components/cart/cart-counter-button'),
  {
    resolveComponent: (module) => module.CartCounterButton,
    ssr: false,
  }
);

//export const doNotPrerender = true;

export const Page: RenderablePage = () => {
  const { t } = useTranslation('common');
  const width = useWindowSize().width;
  const [categorySlug] = useSearchParam('category');
  const [search] = useSearchParam('search');

  const { data: category } = useCategoryQuery({
    variables: { slug: categorySlug! },
    skip: !categorySlug,
  });

  useEffect(() => {
    gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, [categorySlug ?? '', search ?? '']);

  const seoTitle = `${
    category?.collection ? `${category.collection.name} - ` : ''
  }${t('seo.site')} - ${t('seo.main-title')}`;
  const seoDesc = t('seo.main-desc', {
    category: category?.collection?.name ?? t('seo.main-cat-fallback'),
  });
  const seoUrl = category?.collection
    ? `https://tugo.no/?category=${category.collection.slug}`
    : 'https://tugo.no/';

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name='description' content={seoDesc} />
        <link rel='canonical' href={seoUrl} />
        <meta name='og:title' content={seoTitle} />
        <meta name='og:description' content={seoDesc} />
        <meta name='og:url' content={seoUrl} />
      </Helmet>
      <Standard />
      {width > 1023 && <CartCounterButton />}
    </>
  );
};

Page.Layout = SiteLayout;
