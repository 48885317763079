import loadable from '@loadable/component';
import PropTypes from 'prop-types';

import { StaticSidebarVerticalRectangleVendors } from '#components/vendors/static-sidebar-vertical-rectangle-vendors';
import { ErrorMessage } from '#components/ui/error-message';
import { SortOrder, useSellersQuery } from '#framework/__generated__';

/*const StickySidebarListVendors = loadable(
  () => import('#components/vendors/sticky-sidebar-list-vendors'),
  { resolveComponent: (module) => module.StickySidebarListVendors }
);*/
/*const StickySidebarBoxedVendors = loadable(
  () => import('#components/vendors/sticky-sidebar-boxed-vendors')
);*/

const MAP_VENDOR_TO_GROUP: Record<string, any> = {
  //classic: StickySidebarListVendors,
  //modern: StickySidebarBoxedVendors,
  standard: StaticSidebarVerticalRectangleVendors,
  //default: StickySidebarListVendors,
};

const Vendors: React.FC<{ layout: string; className?: string }> = ({
  layout,
  className,
}) => {
  const { data, loading, error } = useSellersQuery();

  if (error) return <ErrorMessage message={error.message} />;

  const Component = layout
    ? MAP_VENDOR_TO_GROUP[layout]
    : MAP_VENDOR_TO_GROUP['default'];

  return (
    <Component
      notFound={!data?.sellers.length}
      vendors={data?.sellers ?? []}
      loading={loading}
      className={className}
    />
  );
};

Vendors.propTypes = {
  layout: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Vendors;
